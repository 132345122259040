<template>
  <div>
    <!-- COVER contiene il box introduttivo che può contenere un messaggio promo o i campi di ricerca dell'Homepage -->
    <div class="cover">
      <div class="cover__filter"></div>
      <div class="cover__image"></div>
      <div class="cover__caption">
        <div class="cover__caption__copy">
          <h1>NON SEI ANCORA ABBONATO AI MIEI VOLUMI?</h1>
          <a v-if="settings" :href="settings.marketing.contatta_agenti"
                target="_blank" class="cta orange" title="Richiedi subito come fare">Richiedi subito come fare</a>
        </div>
      </div>
    </div>

    <!-- SECTION per gestire le varie sezioni a tutta larghezza - con le classi bg-grey | bg-turquoise | bg-lightBlue si gestisce il background della sezione  -->
    <div class="section">
      <v-container>
        <div class="intro-page">
          <h2>I miei volumi</h2>
          <p><strong>I MIEI VOLUMI</strong> è il servizio di <strong>Giuffrè Francis Lefebvre</strong> che ti permette di consultare in formato digitale tutti i libri acquistati presso il tuo agente di fiducia o sul nostro shop. 
</p><p>Grazie a I MIEI VOLUMI potrai: </p>
        </div>

        <div class="text-page">
          <ul>
            <li>Creare la tua lista di <strong>preferiti</strong> e consultare in modo facile e veloce i testi che più ti interessano</li>
            <li>Potrai <strong>stampare</strong> tutti i capitoli dei titoli presenti ne I MIEI VOLUMI senza limitazioni</li>
            <li>Fruire tutti i contenuti digitali sul <strong>nuovo sfogliatore</strong> con la possibilità di inserire note, evidenziare i contenuti </li>
            <li>Avere <strong>aggiornamenti dei contenuti costanti</strong> attraverso l’inserimento di nuovi titoli consultabili</li>
          </ul>
          <p>Contatta subito il tuo agente di fiducia per scoprire come accedere ai servizi de I MIEI VOLUMI o contattaci direttamente.</p>
        </div>
      </v-container>
    </div>

    
  </div>
</template>
<script>
export default {
  props: {
    settings: {
      type: Object
    },
  },
  data: vm => ({
    user: null
  }),
  mounted() {
  }
};
</script>
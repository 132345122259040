<template>
  <div v-if="!overlay">
    <div v-if="okRules">
      <div class="search bg-turquoise" style="z-index: 500">
        <div class="search__caption">
          <div class="search__caption__copy">
            <h1>Volumi</h1>
            <div class="search__caption__copy__searchbox">
              <v-text-field
                class="ctlg"
                v-on:keyup="keyPress"
                dense
                v-model="searchText"
                placeholder="Cerca ne I Miei Volumi"
                @click:append="
                  page = 1
                  research(0, 8)
                "
                rounded
                append-icon="search"></v-text-field>
              <a class="cta blue advanced" title="Ricerca Avanzata" style="font-size: 12pt" @click="showResAdv = !showResAdv" v-if="!showResAdv">
                <span>Ricerca Avanzata</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="search__advanced" v-show="showResAdv">
        <!-- pannello ricerca avanzata - va mostrato al click della CTA Ricerca Avanzata -->
        <div class="search__advanced__copy">
          <span class="panel-close">
            <span class="material-icons" @click="showResAdv = false">close</span>
          </span>
          <div class="search__advanced__copy__item">
            <div class="advanced__row">
              <label class="advanced__row__title">Data</label>
              <hr class="border-blue" />
            </div>
            <v-flex xs12 sm6 md4 class="advanced__row">
              <label style="margin-top: -25px">Da</label>
              <v-text-field label="Anno" outlined type="number" style v-model="dateFrom" :rules="[rules.min, rules.max]"></v-text-field>
              <label style="margin-top: -25px; margin-left: 10px">A</label>
              <v-text-field label="Anno" outlined type="number" v-model="dateTo" :rules="[rules.max]"></v-text-field>
            </v-flex>
          </div>
          <div class="search__advanced__copy__item">
            <div class="advanced__row">
              <label class="advanced__row__title">Autore</label>
              <hr class="border-blue" />
            </div>
            <v-flex xs12 sm6 md4 class="advanced__row">
              <input type="text" name v-model="author" placeholder="Autore" />
            </v-flex>
          </div>
          <div class="search__advanced__copy__item">
            <div class="advanced__row">
              <label class="advanced__row__title">Volume</label>
              <hr class="border-blue" />
            </div>
            <div class="flex advanced__row xs12 sm6 md4">
              <input class="volume__code" type="text" name placeholder="Codice volume" v-model="volume" />
              <input class="isbn__code" type="text" name placeholder="Codice ISBN" v-model="isbn" />
            </div>
            <div class="search__advanced__copy__actions" v-if="showResAdv">
              <!-- va mostrato quando i campi di ricerca vengono popolati -->
              <a
                href="#"
                class="cta orange"
                title="Cerca"
                @click="
                  page = 1
                  research(0, 8)
                ">
                <span>Cerca</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <v-container>
          <div class="intro">
            <h2>I miei volumi</h2>
          </div>
          <div class="filter">
            <v-combobox
              class="ctlg mater"
              v-model="materiaSelected"
              :items="materie"
              light
              item-text="nome_interesse"
              dense
              placeholder="Materia"
              rounded
              @change="setMateria()"
              :menu-props="{ auto: true, maxHeight: '250', minWidth: '350' }"></v-combobox>
            <v-switch class="swtch" dense v-model="fav" style="margin-left: 20px; margin-top: 8px" color="green" :label="`PREFERITI`" @change="toggleFav()"></v-switch>
          </div>
          <div v-if="last">
            <v-container style="margin-top: -50px; background: white" class="hidden-sm-and-down">
              <v-pagination style="justify-content: start" v-if="last.length > 0" v-model="page" :length="totalPages" :total-visible="8" circle></v-pagination>
              <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
              <div class="grid clearfix" style="margin-top: 30px">
                <div class="grid__item" v-for="item in last" :key="item" @click="detail(item)">
                  <a class="grid__item__caption">
                    <div class="grid__item__caption__image">
                      <div class="grid__item__caption__corner" v-if="item._source.provvedimento.utente.demo">DEMO</div>
                      <v-img v-if="item._source.provvedimento.campiCalcolati.cover_url" height="306" width="217" :src="getImage(item._source)"></v-img>

                      <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                    </div>
                    <div class="grid__item__caption__text">
                      <h4>{{ item._source.provvedimento.titolo }}</h4>
                      <p>
                        <span v-if="item._source.provvedimento.collana">{{
                          item._source.provvedimento.collana.descrizione ? item._source.provvedimento.collana.descrizione : item._source.provvedimento.collana[0].descr
                        }}</span>
                        <br />
                        <span style="font-weight: bold; display: block" v-if="item._source.autori && item._source.autori[0]"
                          >{{ item._source.autori[0].nome.substring(0, 1) }}. {{ item._source.autori[0].cognome }}</span
                        >
                        <span style="font-weight: bold" v-if="item._source.autori && item._source.autori[1]"
                          >{{ item._source.autori[1].nome.substring(0, 1) }}. {{ item._source.autori[1].cognome }}</span
                        >
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
              <!--<div class="carousel">
              <div class="corousel__pagination-bullets"></div>
              </div>-->
            </v-container>

            <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="height: 306px; !important; width: 217px; !important" v-show="last && last.length > 0">
              <v-carousel v-model="model" hide-delimiters @change="carousel(item)">
                <v-carousel-item v-for="item in last" :key="item" @click="detail(item)">
                  <v-img
                    v-if="item._source.provvedimento.campiCalcolati.cover_url"
                    height="306"
                    width="217"
                    :src="
                      fixCopertine(
                        settings.copertina +
                          '/pdf/' +
                          item._source.provvedimento.idDocMaster
                            .toString()
                            .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                          '/' +
                          item._source.provvedimento.campiCalcolati.cover_url +
                          ''
                      )
                    "></v-img>

                  <v-img v-else width="217" height="306" :src="settings.configuration.placeholderIMG"></v-img>
                </v-carousel-item>
              </v-carousel>
            </v-card>
            <v-row justify="space-around" v-if="currentItem && current" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
              <div class="grid__item__caption__text">
                <h4>{{ currentItem._source.provvedimento.titolo }}</h4>
                <p>
                  <span v-if="currentItem._source.provvedimento.collana">{{
                    currentItem._source.provvedimento.collana.descrizione
                      ? currentItem._source.provvedimento.collana.descrizione
                      : currentItem._source.provvedimento.collana[0].descr
                  }}</span>
                  <br />
                  <span style="font-weight: bold" v-if="currentItem.autori && currentItem.autori[0]"
                    >{{ currentItem.autori[0].nome.substring(0, 1) }}. {{ currentItem.autori[0].cognome }}</span
                  >
                  <span style="font-weight: bold" v-if="currentItem.autori && currentItem.autori[1]"
                    >{{ currentItem.autori[1].nome.substring(0, 1) }}. {{ currentItem.autori[1].cognome }}</span
                  >
                </p>
              </div>
            </v-row>
          </div>
          <h1 v-else style="text-align: center; margin: 0 auto">
            <v-progress-circular style="margin-top: 20px" indeterminate color="primary"></v-progress-circular>
          </h1>
        </v-container>
      </div>
    </div>
    <div v-else style="margin-top: -25px">
      <NoContentView :settings="settings"></NoContentView>
    </div>
  </div>
</template>
<style>
  .ctlg .v-input__slot {
    border: solid 1px #cdcdcd;
    height: 40px !important;
    font-size: 10pt;
  }

  .v-input__icon.v-input__icon--append {
    margin-top: 3px;
  }
  .v-card {
    transition: opacity 0.3s ease-in-out;
  }
  .v-menu__content {
    /*box-shadow: none !important;*/
  }
  .v-card:not(.on-hover) {
    opacity: 0.8;
  }
  .order.v-list.v-sheet.v-sheet--tile.theme--light.v-list--dense {
    margin-top: 40px !important;
    margin-left: 125px !important;
    overflow-y: hidden !important;
  }
  .titl {
    cursor: pointer;
  }
  .titl:hover {
    text-decoration: underline;
  }

  .v-input__slot input::-webkit-outer-spin-button,
  .v-input__slot input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .v-input__slot input[type='number'] {
    border: none;
  }
  .search__caption__copy__searchbox .cta.orange {
    display: block;
    font-size: 10pt;
  }
  .ctlg {
    width: 50%;
    display: inline-flex;
  }

  i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
    cursor: pointer !important;
  }

  @media (max-width: 767px) {
    .ctlg {
      width: 100% !important;

      margin-left: 0px !important;
    }
    .mater {
      padding-right: 40px;
    }
  }
  @media (max-width: 767px) {
    .swtch {
      margin-left: 150px !important;
    }
  }
</style>
<script>
  import NoContentViewVue from '../components/volumes/NoContentView.vue'
  import axios from 'axios'
  import { mapState } from 'vuex'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      NoContentView: NoContentViewVue
    },
    computed: {
      ...mapState(['profile'])
    },
    data: (vm) => ({
      page: 1,
      totalPages: 1,
      loaded: false,
      dateFrom: null,
      dateTo: null,
      author: '',
      user: null,
      catalogue: null,
      materie: [],
      fav: false,
      materiaSelected: null,
      totalResults: 0,
      searchText: null,
      chapterText: '',
      errorMin: false,
      errorMax: false,
      volume: null,
      isbn: null,
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      list: [],
      last: null,
      showResAdv: false,
      nlp: null,
      currentItem: null,
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ]
    }),
    watch: {
      page(val) {
        this.research((val - 1) * 8, 8, true, true)
        this.$gtm.trackEvent({
          event: 'sezione',
          azione: 'open',
          descrizione: 'miei volumi',
          proprieta: val,
          utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
          crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
          stato: ''
        })
      },
      profile(val, old) {
        if (val.roles) {
          this.checkRules(true)
        }
      }
    },
    mounted() {
      //this.$gtm.trackView('pageview', this.$route.path)

      this.$emit('checkQSTicket', this.$route.query) //va messo in tutte le viste

      if (this.authorization.jwt) {
        this.user = this.authorization
        this.checkRules()
      }
      if (this.settings.freeToken == false && this.okRules) {
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }

        if (localStorage.materie) {
          //controllo la data di scadenza per il refresh, settata a 20 min
          if (localStorage.dateSavedMaterie) {
            var now = Date.parse(new Date()) / 1000
            var exp = parseInt(localStorage.dateSavedMaterie) + parseInt(this.settings.configuration.timeoutReloadMaterie)
            if (now >= exp) {
              this.loadMaterie(header)
            } else {
              this.materie = JSON.parse(localStorage.materie)
            }
          } else {
            localStorage.materie = []
            this.loadMaterie(header)
          }
        } else {
          this.loadMaterie(header)
        }

        //

        this.recoverVolumes()
      } else {
      }
    },
    created() {
      this.$gtm.trackEvent({
        event: 'sezione',
        azione: 'open',
        descrizione: 'miei volumi',
        proprieta: this.page,
        utente: this.authorization && this.authorization.profile && this.authorization.profile.userId ? this.authorization.profile.userId : null,
        crmid: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : null,
        stato: ''
      })
    },
    methods: {
      async REQUEST(call, header, method, type, body, first, changingPage) {
        var self = this
        this.loading = true
        if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'search':
                  self.last = response.data.es.list ? response.data.es.list : []
                  self.currentItem = self.last && self.last.length > 0 ? self.last[0] : null
                  self.totalResults = response.data.es.total ? response.data.es.total : 0
                  self.totalPages = parseInt(Math.round(self.totalResults / 8)) >= 1 ? parseInt(Math.ceil(self.totalResults / 8)) : 1
                  self.loaded = true
                  if (!first) {
                    self.settings.catalogo = {
                      search: response.data,
                      materiaSelected: self.materiaSelected,
                      materie: self.materie,
                      fromDetail: false,
                      filters: {
                        dateFrom: self.dateFrom,
                        dateTo: self.dateTo,
                        author: self.author,
                        typeResearch: 'I miei volumi',
                        currentPage: self.page,
                        fromVolumes: true,
                        fromUnlimited: false
                      }
                    }

                    if (changingPage) {
                    } else {
                      self.$router.push({
                        path: 'results',
                        query: { more: true }
                      })
                    }
                  }
                  self.showResAdv = false
                  break
              }
            })
            .catch(function (error) {
              console.log(error)
              //localStorage.token_biblio_vol = null;
              //localStorage.token_biblio_vol_jwt = self.settings.free.jwt;
              //self.token = self.settings.free.jwt;
              //self.settings.freeToken = true;
            })
        } else {
          await axios
            .get(call, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'materie':
                  self.materie = response.data.materie
                  self.materie.unshift({
                    nome_interesse: 'Tutto il catalogo'.toUpperCase()
                  })
                  localStorage.materie = JSON.stringify(self.materie)
                  localStorage.dateSavedMaterie = Date.parse(new Date()) / 1000
                  break
              }
            })
            .catch(function (error) {
              self.loaded = true
            })
        }
      },
      fixCopertine(uri) {
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
      checkRules(recall) {
        var role37 = this.profile.roles.find((x) => x.pos == 37 || x.pos == 'DemoVolume')
        if (role37) this.okRules = true
        else {
          this.okRules = false
        }
        if (recall) {
          this.loadMaterie()
          this.recoverVolumes()
        }

        this.$forceUpdate()
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      toggleFav() {
        this.page = 1
        this.research((this.page - 1) * 8, 8, true, true)
      },
      check(val, from) {
        //value <= vm.dateTo || 'Errore nelle date'
        if (from) {
          if (parseInt(val) > parseInt(this.dateTo)) {
            return 'Errore nelle date.'
          }
        } else {
          if (val != '' && parseInt(val) < parseInt(this.dateFrom)) return 'Errore nelle date.'
        }
        return true
      },
      loadMaterie(header) {
        this.REQUEST(this.settings.configuration.apiURL + '/materie', header, 'GET', 'materie', null)
      },
      moreResults() {
        this.settings.catalogo = null
        this.$router.push({
          path: 'results',
          query: { more: true }
        })
      },
      carousel(item) {
        this.currentItem = this.last[this.model]
      },
      setMateria() {
        this.page = 1
        this.research((this.page - 1) * 8, 8, true, true)
      },
      setOrder(item) {
        this.currentOrder = item
        this.research((this.page - 1) * 8, 8)
      },
      verifyUserConditions(item, type) {
        if (type == 'unlimited') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (item._source.provvedimento.utente.unlimited == false && item._source.provvedimento.is_unlimited) {
              return true
            } else return false
          }
        } else if (type == 'abbonato') {
          if (this.settings.freeToken) {
            return false
          } else {
            if (item._source.provvedimento.utente.volume) {
              return true
            } else return false
          }
        }
      },
      generateCover(item) {
        if (item._source.provvedimento.campiCalcolati && item._source.provvedimento.campiCalcolati.cover_url && item._source.provvedimento.idDocMaster) {
          return (
            this.settings.copertina +
            '/pdf/' +
            item._source.provvedimento.idDocMaster
              .toString()
              .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
            '/' +
            item._source.provvedimento.campiCalcolati.cover_url +
            '?width=280'
          )
        } else {
          return this.settings.configuration.placeholderIMG
        }
      },
      detail(item) {
        this.$router.push({
          path: '/details',
          query: { cod_vol: item._source.provvedimento.codVol, from: 'I miei volumi' }
        })
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.page = 1
          this.research((this.page - 1) * 8, 8)
        }
      },
      getImage(item) {
        //BVA-93
        let prefix = this.settings.copertina + '/pdf/'
        let uri = item.provvedimento.idDocMaster.toString().substring(item.provvedimento.idDocMaster.toString().length - 3, item.provvedimento.idDocMaster.toString().length)
        let fix = '/' + item.provvedimento.campiCalcolati.cover_url
        let final = prefix + uri + fix.replace('//', '/') + '?width=217&fit=outside'
        return final.replace(/([^:]\/)\/+/g, '$1')
      },
      recoverVolumes(header) {
        this.last = []
        var header = {
          Authorization: localStorage.token_biblio_vol_jwt
        }
        var body = {
          filters: [
            { name: 'codiciCliente', value: true },
            { name: 'codiciPreferiti', value: this.fav }
          ],
          sort: { name: 'provvedimento.data', order: 'desc' }
        }
        this.REQUEST(this.settings.configuration.apiURL + '/search/volumi/0/8', header, 'POST', 'search', body, true)
      },
      research(from, to, fav, changingPage) {
        var body = {}
        var stop = false
        if ((this.searchText != null && this.searchText.length > 0) || this.showResAdv || fav) {
          if (this.materiaSelected && this.materiaSelected.nome_interesse.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'codiciCliente', value: true }]
            }
            if (this.searchText != null && this.searchText.length > 0) {
              body.filters.push({
                name: 'materia',
                value: [this.materiaSelected.interesse_id]
              })
              //nel caso togli questo che non è corretto, se ti aprono bug.
            } else {
              body.filters.push({
                name: 'materia',
                value: [this.materiaSelected.interesse_id]
              })
              body.filters.push({
                name: 'codiciPreferiti',
                value: this.fav
              })
            }
          } else {
            body = {
              filters: [{ name: 'codiciCliente', value: true }]
            }

            if (this.searchText != null && this.searchText.length > 0) {
            } else {
              body.filters.push({
                name: 'codiciPreferiti',
                value: this.fav
              })
            }
          }
          if (this.searchText) {
            body.text = this.searchText
          }
          if (!this.searchText && this.showResAdv && this.author) {
            body.text = this.author
          }

          if (!this.searchText) {
            //PBV-277
            body.sort = {
              name: 'provvedimento.data',
              order: 'desc'
            }
          } else body.sort = { name: '_score', order: 'desc' }

          var flagAuthor = false
          if (this.showResAdv) {
            var filterDate = {}
            //verifiche
            var dateFrom = this.dateFrom ? this.dateFrom : 1800
            var dateTo = this.dateTo ? this.dateTo : new Date().getFullYear()
            if (parseInt(dateFrom) <= parseInt(dateTo) && parseInt(dateFrom) > 0 && parseInt(dateTo) > 0) {
              if (dateFrom != null || dateTo != null) {
                filterDate.name = 'range'
                var values = {
                  from: dateFrom ? dateFrom.toString() + '-01-01T00:00:00' : '1930-01-01T00:00:00',
                  to: dateTo ? dateTo.toString() + '-12-31T00:00:00' : '2100-01-01T00:00:00'
                }
                filterDate.value = values
                body.filters.push(filterDate)
              }
              var filterAuthor = {}
              if (this.author != '' && this.author != null) {
                filterAuthor.name = 'autore'
                var values = {
                  cognome: this.author
                }
                filterAuthor.value = values
                body.filters.push(filterAuthor)
                flagAuthor = true
              }
              var filterVolume = {}
              if (this.volume != '' && this.volume != null) {
                //gestione errore 9 caratteri
                if (this.volume.length < 9) {
                  alert('Il codice volume deve essere di 9 caratteri')
                }
                filterVolume.name = 'codVol'
                filterVolume.value = this.volume.toString()
                body.filters.push(filterVolume)
              }
              var filterISBN = {}
              if (this.isbn != '' && this.isbn != null) {
                filterISBN.name = 'isbn'
                filterISBN.value = this.isbn.toString()
                body.filters.push(filterISBN)
              }
            } else {
              if (this.dateFrom && this.dateTo) stop = true
            }
          }
          if (!stop) {
            var nlp = ''
            var header = {
              Authorization: localStorage.token_biblio_vol_jwt
            }
            this.REQUEST(this.settings.configuration.apiURL + '/search/volumi/' + from + '/' + to + nlp, header, 'POST', 'search', body, false, changingPage)
          }
        }
      }
    }
  }
</script>
